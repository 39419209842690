@font-face {
  font-family: "JetBrains Mono Bold";
  src: url("assets/fonts/static/JetBrainsMono-Bold.ttf") format("ttf"),
    url("assets/fonts/static/JetBrainsMono-BoldItalic.ttf") format("ttf"),
    url("assets/fonts/JetBrainsMono-Italic-VariableFont_wght.ttf") format("ttf"),
    url("assets/fonts/JetBrainsMono-VariableFont_wght.ttf") format("ttf");
}

html {
  background: #18171f;
}
.App {
  font-family: "JetBrains Mono";
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  font-family: "JetBrains Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #817d92;
  line-height: 32px;
  margin-top: 31px;
  margin-bottom: 31px;
}

.Form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 540px;
  height: 632px;
  left: 450px;
  top: 196px;
  background: black;
  color: #e6e5ea;
}

.Output {
  background: #24232c;
  display: flex;
  justify-content: space-between;
  width: 540px;
  height: 80px;
}

.left {
  display: flex;
  justify-content: flex-start;
  width: 65%;
  padding-left: 32px;
  margin-top: 19px;
  margin-bottom: 19px;
  font-size: 32px;
  align-items: center;
}

.left > p {
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 20%;
  padding-right: 28px;
  font-size: 18px;
}

img {
  height: 24px;
  width: 21px;
  padding-left: 16px;
}
.settings-container {
  background: #24232c;
  width: 540px;
  height: 540px;
}

.slider-container {
  background: #24232c;
  display: flex;
  justify-content: space-between;
  height: 43px;
  padding-top: 24px;
  margin-top: 24px;
}
.slider-left {
  display: flex;
  justify-content: flex-start;
  width: 80%;
  padding-left: 32px;
  font-size: 18px;
  align-items: center;
}
.slider-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 20%;
  padding-right: 32px;
  font-size: 32px;
}

.slider-bar {
  width: 476px;
  height: 28px;
  left: 482px;
  top: 383px;
  margin-top: 16px;
  padding: 0 32px 0 32px;
}
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #e6e5ea;
  outline: none;
  -webkit-transition: 0.5s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #e6e5ea;
  cursor: pointer;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  padding: 0 32px 0 32px;
  font-size: 18px;
  margin-top: 32px;
}

.checkboxContainer label {
  margin-bottom: 20px;
  margin-left: 0;
}

.checkboxContainer input {
  accent-color: #a4ffaf;
  width: 20px;
  height: 20px;
  margin-right: 24px;
  vertical-align: bottom;
  position: relative;
}

.strength-container {
  display: flex;
  justify-content: space-between;
  height: 72px;
  margin: 12px 0 32px 0;
  padding: 0 32px 0 32px;
}

.leftStrength {
  font-size: 18px;
  color: #817d92;
  background-color: black;
  width: 30%;
  display: flex;
  align-items: center;
}

.rightStrength {
  font-size: 24px;
  background-color: black;
  width: 70%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 31.5px;
}

.leftStrength > p {
  margin-left: 31.5px;
}

.rightStrength > p {
  margin-right: 7.5px;
}

.coloredContainers {
  display: flex;
  justify-content: space-between;
}

.colorBlock {
  width: 10px;
  height: 28px;
  background-color: #f8cd65;
  margin-left: 8px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button {
  background: #a4ffaf;
  height: 65px;
  width: 476px;
  margin: 0 32px 0 32px;
  font-family: "JetBrains Mono";
  font-weight: bold;
}

/*Tablet*/
@media screen and (max-width: 1279px) and (min-width: 601px) {
}

/*Mobile*/

@media screen and (max-width: 600px) {
  .App-header {
    padding-top: 16px;
    font-size: 16px;
    margin: 0;
  }
  .Form {
    width: 343px;
    height: 540px;
  }

  .Output {
    width: 343px;
    height: 80px;
  }

  .left {
    font-size: 24px;
    padding-left: 16px;
    width: 70%;
  }
  .right {
    font-size: 16px;
    width: 30%;
  }

  img {
    height: 20px;
    width: 17.5px;
    padding-left: 15.5px;
  }

  .settings-container {
    width: 343px;
    height: 443px;
  }

  .slider-container {
    height: 67px;
    padding-top: 0;
    margin-top: 16px;
  }
  .slider-left {
    padding-left: 16px;
    font-size: 16px;
  }
  .slider-right {
    padding-right: 16px;
    font-size: 24px;
  }

  .slider-bar {
    width: 311px;
    height: 28px;
    left: 482px;
    top: 383px;
    margin-top: 0;
    padding: 0 16px 0 16px;
  }

  .checkboxContainer {
    padding: 0 16px 0 16px;
    font-size: 16px;
    margin-top: 16px;
  }

  .checkboxContainer label {
    margin-bottom: 16px;
    margin-left: 0;
  }

  .checkboxContainer input {
    accent-color: #a4ffaf;
    width: 20px;
    height: 20px;
    margin-right: 20px;
    vertical-align: bottom;
    position: relative;
  }

  .strength-container {
    height: 56px;
    margin: 12px 0 16px 0;
    padding: 0 16px 0 16px;
  }

  .leftStrength {
    font-size: 16px;
  }

  .rightStrength {
    font-size: 18px;
    padding-right: 16px;
  }

  .leftStrength > p {
    margin-left: 16px;
  }

  .button {
    height: 56px;
    width: 311px;
    font-size: 16px;
    margin: 0 16px 0 16px;
  }
}
